 
// table-white-space
.table-white-space {
  th,
  td {
    white-space: nowrap;
  }
}

// table th, td padding
.table {
  thead,
  tfoot {
    th {
      vertical-align: top;
      text-transform: uppercase;
      font-size: $table-th-font-size;
      letter-spacing: 0.5px;
    }
  }
  th,
  td {
    padding: $table-cell-padding 2rem;
    vertical-align: middle;
  }
  &.table-sm {
    th,
    td {
      padding: $table-cell-padding-sm 0.5rem;
      &:first-child {
        padding-left: 0.75rem;
      }
    }
  }
}
// if we are not using table-light class then also header color should apply
.table:not(.table-dark):not(.table-light) {
  thead:not(.thead-dark) th,
  tfoot:not(.thead-dark) th {
    background-color: $table-head-bg;
  }
}
// table inside card, don't need margin bottom
.table-hover {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
// Table - hover animation
.table-hover-animation {
  background-color: $body-bg;
  thead {
    th {
      border-bottom: 0;
    }
  }
  th,
  td {
    border: 0;
  }
  tbody {
    tr {
      transition: all 0.25s ease;
      background-color: $white;
      &:hover {
        transform: translateY(-4px);
      }
    }
  }
}

// table border radius
.card .table {
  margin-bottom: 0;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  tbody {
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: $border-radius;
      }
      td:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}

// Contexual table inside card radius
.card {
  table {
    tr[class*='table-']:last-child {
      td:first-child {
        border-bottom-left-radius: $border-radius;
      }
      td:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}

// IE Specific CSS
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .table-hover-animation {
    tr {
      &:hover {
        border-bottom-color: $table-border-color;
      }
    }
  }
}
